import ReactPlayer from 'react-player';
import Work from './lib/wrkcard.js';

function Wrkscroll(props){
  return(
<div className="scrollableContainer">
<ul className="list">
  <li className="item"><Work Link="https://superjob.ru" Name="СуперДжоб" Job="Руководитель направления коммуникационного дизайна" Time="Сейчас" id="sj" wdescription="Помогаю бизнесу коммуницировать с аудиторией: разрабатываю рекламные кампании, генерю графические концепции, учу дизайнеров производить впечатление. Вместе с Ромой Ватриковским разработали новую айдентику."/></li>
  <li className="item"><Work Link="https://sber.ru" Name="Сбер" Job="Ведущий дизайнер/Арт-директор" Time="2020–2022" id="sber" wdescription="Работал в Центральном аппарате. Принял участие в большом ребрендинге Сбера: участвовал в корректировке брендбука и в разработке новой айдентики для дочерних компаний Экосистемы. Занимался операционным дизайном, проводил брейнштормы, дизайн-ревью, разрабатывал графические концепции мероприятий и контролировал процесс их воплощения в жизнь."/></li>
  <li className="item">  <Work Link="https://artlebedev.ru/legostaeff" Name="Студия Артемия Лебедева" Job="Дизайнер" Time="2019–2020" id="artleb" wdescription="Дизайнил логотипы и айдентику, погрузился в автоматизацию дизайна, разработал и написал на JS интерфейс для одного из автоматизационных решений. Еще написал несколько упрощающих жизнь скриптов для адоби"/>
</li>
  <li className="item">  <Work Name="Адванза" Link="https://advanza.ru" Job="Арт-директор" Time="2018–2019" id="advnz" wdescription="Придумывал и развивал концепции мероприятий для крутейших брендов. Презентовал решения клиентам. Контролировал работу дизайнеров. Разработал с командой руководство по коммуникации для РОПов одного очень известного автобренда "/>
  </li>
  <li className="item"><Work Name="Студия Артемия Лебедева" Link="https://artlebedev.ru/legostaeff" Job="Главный дизайнер Кафетериуса" Time="2016–2018" id="artleb2" wdescription="Поддерживал, развивал, холил и лелеял айдентику одной из самых уютных сетей кофеен. Разрабатывал проекты кафе и контролировал их воплощение в жизнь. Успел побыть графическим дизайнером, верстальщиком, иллюстратором, менеджером, визуализатором, интерьерщиком, прорабом и много кем еще"/>
</li>
  <li className="item">  <Work Name="Фриланс" Time="...–2016" id="fr"/></li>

</ul>
<a id="want" href="#dlyanachala">Хочу добавить свою компанию в этот список</a>
</div>
)};

export default Wrkscroll;
