import ReactPlayer from 'react-player';
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import data from "./lib/work.json";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

const useStyles = makeStyles({
  root: {
    background: '#000',
    border: 0,
    borderRadius: 3,
    width: '80%',
    marginLeft: '10%',
    height: 'fit-content',
    color: 'white',
  },
});

function WorkTabs(props){
  const [value, setValue] = React.useState(0);
  console.log(data[0].name);
  const classes = useStyles();
  return(
<div>
      <Paper square className={classes.root}>
        <Tabs
          value={value}
          textColor="white"
          indicatorColor="green"
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <Tab label="СуперДжоб" value="0" id="sj_tab" className="Tabs"/>
          <Tab label="Сбер" value="1" id="sj_tab" className="Tabs"/>
          <Tab label="Студия Лебедева x2" value="2" id="sj_tab" className="Tabs"/>
          <Tab label="Адванза" value="3" id="sj_tab" className="Tabs"/>
          <Tab label="Студия Лебедева" value="4" id="sj_tab" className="Tabs"/>
          <Tab label="Фриланс" disabled id="sj_tab_inactive" className="Tabs"/>
        </Tabs>
        <div className="dynamicD">
        <h1>{data[value].name}</h1>
        <span id="wtime_new">{data[value].date}</span>
        <p id="role">{data[value].role}</p>
        <p>{data[value].description}</p>
        <a id="want" href="#dlyanachala">Хочу добавить свою компанию в&nbsp;этот список</a>
        </div>
      </Paper>
    </div>
  )
};

export default WorkTabs;
