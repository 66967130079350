import ReactPlayer from 'react-player';
function Work(props){
  return(
    <a id="wlink" href={props.Link}>
    <div className="workwrap">
    <div id={props.id} className="work">

    <p id="wtime">{props.Time}</p>
    <p id="job"><span>{props.Name}</span> <br/>{props.Job}</p>
    <p id="wdescription">{props.wdescription}</p>
    </div>
    <div className="workDescrip">
    </div>
    </div>
    </a>
  )
}

export default Work;
