import ReactPlayer from 'react-player';
import Plate from './plate.js';
import Lock from './locked.js'
function Card(props){
  return(

    <div className={props.className}>
    {props.Lock}
    <video width="100%" height="auto" autoPlay="autoplay" loop muted playsInline preload="true" className="video">
    <source src={props.video} type='video/mp4'/>
    </video>
    <Plate className="PlateDiv" header={props.name}/>

    </div>
  )
}

export default Card;
