import ReactPlayer from 'react-player';
function Lock(props){
  return(
    <div className="locDiv">

  <svg fill={props.fill} id={props.side}>
  <path d="M29.9,40.2c-1.2,0-16.6-0.1-17.5-0.1c-2.6,0-3.3-1.3-3.3-3.2v-1.3L33.3,36v1.2C33.3,39.1,32.5,40.2,29.9,40.2z
  	 M9,29.4l24.2-0.4v4.4L9,33.4V29.4z M9,24.7c0-1.8,0.9-3,2.9-3.2c0-2.6,0-5.3,0-5.9c0-11,18.2-10,18.2-0.3c0,0.7,0,3.4,0,6
  	c2.4,0.1,3,1.7,3,3.5v1.9L9,26.7L9,24.7z M25.9,21.3c0-2.5,0-4.8,0-5.2c0-5.3-9.6-5.8-9.7,0.1c0,0.3,0,2.7,0,5.1L25.9,21.3z"/>;
  </svg>

    <span className="lock">Подробный рассказ об&nbsp;этом проекте еще не&nbsp;готов</span>
    </div>
  )
}

export default Lock;
