import ReactPlayer from 'react-player';
function Plate(props){
  return(

    <div className={props.className}>
      <p className="Plate">{props.header}</p>

    </div>
  )
}

export default Plate;
