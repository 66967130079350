import './App.css';
import React from "react";
import ReactPlayer from 'react-player';
import data from "./lib/work.json";
import Card from './lib/card.js';
import Logos from './logos.js';
import CardStat from './lib/cardStat.js';
import Work from './lib/wrkcard.js';
import rako from './media/rako.jpg';
import add from './media/add.jpg';
import Do from './do.js';
import Do1 from './do1.js';
import IpId from './media/1p1d.jpg';
import kus from './media/kus.jpg';
import gostech from './media/gostech.jpg';
import tuchkov from './media/tuchkov.jpg';
import sberСonf from './media/sberConf.jpg';
import oratorClub from './media/oratorclub-site.jpg';
import sber from './media/sber.mp4';
import sberm from './media/sber_mob.mp4';
import Kus from './lib/kus.js';
import Plate from './lib/plate.js';
import Lock from './lib/locked.js';
import SJ_ICONS from './media/sj_icons.mp4';
import SJ_ID from './media/sj.png';
import Sbconf from './media/sbconf.png';
import Cars from './media/cars.png';
import Ava from './media/ava.png';
import Decor from './media/decor.png';
import Sbc from './media/sb-c.png';
import Cat from './media/catdec.png';
import Horse from './media/horse.png';
import Cafe from './media/cafe.png';
import Coffee from './media/coffee.mp4';
import Group from './media/group1.png';
import Cat2 from './media/cat2.png';
import Cat3 from './media/cat3.png';
import Ducks from './media/ducks.png';
import insta from './media/legostaeff.png';
import artlebedev from './media/artleb.jpg';
import Wrkscroll from './scrollwrk.js';
import heart from './media/cases/heart.png';
import salute from './media/cases/salute.png';
import present from './media/cases/present.png';
import portfl from './media/cases/portfl.png';
import piechart from './media/cases/pie-chart.png';
import laptop from './media/cases/laptop.png';
import iphone from './media/cases/iphone.png';
import graphic from './media/cases/graphic.png';
import funnel from './media/cases/funnel.png';
import ellipse from './media/cases/Ellipse.png';
import candys from './media/cases/candys2.png';
import calendar from './media/cases/calendar.png';
import calc from './media/cases/calc.png';
import leftdoor from './media/cases/ad-left-door.png';
import rightdoor from './media/cases/ad-right-door.png';
import inner from './media/cases/ad-inner.png';
import outer from './media/cases/ad-outer.png';
import douter from './media/cases/douter.png';
import horseman from './media/cases/horseman.png';
import lenta from './media/cases/lenta.png';
import balloon from './media/cases/balloon.png';
import balloons from './media/cases/balloons.png';
import couple from './media/cases/couple.png';
import browserS from './media/cases/landing-browser.png';
import portal from './media/cases/portal-01.png';
import bboard from './media/cases/sbbboard.mp4';
import bitva from './media/cases/bitva.jpg';
import bitva2 from './media/cases/bitva2.jpg';
import rrr from './media/cases/rrr.png';
import rrrKV from './media/cases/kv-01.png';
import sl180 from './media/cases/180.png';
import sbb from './media/cases/logoSbB.png';
import sbjersey from './media/cases/jersey.png';
import sbbottle from './media/cases/sb_bottle.png';
import sbpin from './media/cases/sb_pin.png';
import sbbag from './media/cases/sb_bag.png';
import sbbag2 from './media/cases/sb_smallbag.png';
import sbt1 from './media/cases/sbt1.png';
import sbt2 from './media/cases/sbt2.png';
import sbt3 from './media/cases/sbt3.png';
import sbt4 from './media/cases/sbt4.png';
import sbt5 from './media/cases/sbt5.png';
import sbt6 from './media/cases/sbt6.png';
import sbt7 from './media/cases/sbt7.png';
import sbt8 from './media/cases/sbt8.png';
import sbt9 from './media/cases/sbt9.png';
import sbt10 from './media/cases/sbt10.png';
import sbt11 from './media/cases/sbt11.png';
import lockicon from './media/lock-01.svg';
import myata from './media/myata.png';
import WorkTabs from './workTabs.js';




// import kkus from './lib/kus.json';

function App() {

  // const json = require('./lib/kus.json');
  return (
    <div className="App">
      <div className="NewHeader">
        <img id="deco" src={Decor}/>
        <div className="description_1">
          <img src={Ava}/>
          <p className="About"> Привет, я&nbsp;Дима Легостаев, арт-директор, дизайнер, иллюстратор. Делаю компаниям красиво. </p>
        </div>
        <img id="catdec" src={Cat}/>
    </div>
    <Wrkscroll/>
    <br/>
    <WorkTabs id="wrktabs"/>
    <div id="divList">
    <div className="workTable">
    <div className="timeline">

    </div>
    </div>
    </div>

    <div className="wraper">
    </div>
    <div className="wraper">
    <div className="doBlock">
      <div className="doWrap"><Do header="Раковарня 2.0" description="Пишем в тележке про дизайн с&nbsp;Ромой, Димой и&nbsp;Мариной" pict={rako} classN="doCard" link="https://t.me/rakovarnya_2_0" imgId="rako" target="_blank" id="doCard"/></div>
        <div className="doWrap"><Do header="Моя тележка" description="Мой собственный канальчик, в&nbsp;котором я пишу про дизайн, кидаю картиночки, шучу шутки" pict={insta} classN="doCard" link="https://t.me/legostaeffdraw" imgId="rako1" target="_blank" id="doCard"/></div>
      <div className="doWrap"><Do header="Пересечение" description="Коллаборируемся и делаем контент" pict={kus}classN="doCard" link="#randKus" imgId="kus" target="" id="doCard"/></div>
      <div className="doWrap"><Do header="Создать новый проект" description="Жмакай, если хочешь предложить мне поучаствовать в какой-то движухе" pict={add}classN="doCard" link="#dlyanachala" imgId="wrk1"/></div>
        </div>
    </div>
    <div>
    <div id="newsuperjob">
    <img id="s_ellipse" className="s_img" src={ellipse}/>
      <img id="s_salute" className="s_img" src={salute}/>
      <img id="s_laptop" className="s_img" src={laptop}/>
      <img id="s_iphone" className="s_img" src={iphone}/>
      <img id="s_heart" className="s_img" src={heart}/>
    <img id="s_present" className="s_img" src={present}/>
    <img id="s_portfl" className="s_img" src={portfl}/>
    <img id="s_pichart" className="s_img" src={piechart}/>
    <img id="s_graphic" className="s_img" src={graphic}/>
    <img id="s_calc" className="s_img" src={calc}/>
    <img id="s_funnel" className="s_img" src={funnel}/>
    <h3>Новый и красивый СуперДжоб</h3>
    </div>
    <div id="newsuperjob_articles">
    <a className="prjLink" target = "_blank" href="https://vatrikovsky.ru/superjob/identity">
    <Card video={SJ_ICONS} name="Новая айдентика и&nbsp;фирменные иконки" className="card" tagup="#графдизайн #айдентика #упаковка #интерьеры"/>
    </a>
    <div id="cardlift">
    <Lock lock={lockicon} fill="gray" side="lockicon"/>
    <div id="d">
    <img id="din" src={douter}/>
    <img id="horseman" src={horseman}/>
    <img id="dleft" src={leftdoor}/>
    <img id="dright" src={rightdoor}/>
    <img id="dout" src={outer}/>
    </div>
    <Plate header="8 рекламных кампаний"/>
    </div>
    </div>
    <div id="newsuperjob_articles2">
    <div id="cardshablon">
    <div id="shablonivk">
    <Lock lock={lockicon} fill="#ffffff" side="lockicon_topright"/>
    <img id="lenta" src={lenta}/>
    </div>
    <Plate header="Шаблоны для соцсетей"/>
    </div>
    <div id="lendosSh">
    <div id="shablendos">
    <Lock lock={lockicon} fill="#ffffff" side="lockicon_topright"/>
    <img src={browserS} id="browseS"/>
    <img src={balloons} id="balloons"/>
    <img src={candys} id="candys_sh"/>
    <img src={couple} id="couple"/>
    <img src={balloon} id="balloon"/>
    </div>
    <Plate header="Шаблоны лендингов на&nbsp;Тильде"/>
    </div>
    <div id="portaldiv">
    <div id="portal">
    <img id="portalimg" src={portal}/>
    </div>
    <img src={graphic} id="portalHeart"/>
    <img src={iphone} id="portaliphone"/>
    <img src={heart} id="portalgraphic"/>
    </div>
    </div>
    <div id="vsbere">
    <div id="vsbere_txt">
    <h3>Два года в Сбере</h3>
    <p>Дизайнил в Центральном аппарате. Прошел через большой ребрендинг Сбера: участвовал в разработке брендбука и новой айдентики для дочерних компаний Экосистемы. </p>
    <img src={myata}/>
    </div>
    </div>
    <div id="newsber_articles2">
    <div id="wrap180">
    <div id="pic180">
    <Lock lock={lockicon} fill="black" side="lockicon"/>
    <div id="slider180">
    <div id="subslider180">
    </div>
    <img id="sbblogo" src={sbb}/>
    </div>
    <img id="slhead" src={sl180}/>
    <img src={sbjersey} id="sbjersey"/>
    <img src={sbpin} id="sbpin"/>
    <img src={sbbag2} id="sbbag2"/>
    <img src={sbbottle} id="sbbottle"/>
    <img src={sbbag} id="sbbag"/>

    </div>
    <Plate header="Логотип 180-летия СберБанка"/>
    </div>
    <div id="sberteamWrap">
    <div id="sberteam">
    <Lock lock={lockicon} fill="white" side="lockicon_topright"/>
    <img src={sbt1} id="sbt1"/>
    <img src={sbt10} id="sbt10"/>
    <img src={sbt8} id="sbt8"/>
    <img src={sbt9} id="sbt9"/>
    <img src={sbt3} id="sbt3"/>
    <img src={sbt4} id="sbt4"/>
    <img src={sbt5} id="sbt5"/>
    <img src={sbt6} id="sbt6"/>
    <img src={sbt7} id="sbt7"/>
    <img src={sbt2} id="sbt2"/>
    <img src={sbt11} id="sbt11"/>
    </div>
    <Plate header="Логотипы и айдентика для десятков внутренних подразделений Сбера"/>
    </div>
    </div>
    <div id="newsber_articles">
    <Card video={bboard} name="Концепты рекламы" className="card2" Lock={<Lock lock={lockicon} fill='gray' side='lockicon' style="position:absolute"/>}/>

    <div id="bitvaWrap">
    <div id="bitva">
    <Lock lock={lockicon} fill="gray" positionX="left" side="lockicon_topright"/>
    <img src={bitva} id="b1"/>
    <img src={bitva2} id="b2"/>
    </div>
    <Plate header="Айдентика Битвы Стартапов"/>
    </div>
    <div id="rrraWrap">
    <Lock lock={lockicon} fill="gray" side="lockicon_topright" style="position:relative"/>
    <div id="rrr">
    <img src={rrrKV} id="rrrKV"/>
    <img src={rrr} id="rrrLogo"/>
    </div>
    <Plate header="Логотип и айдентика отчета о Рисках России"/>
    </div>
    </div>
    </div>
{/*
    <div className="Prostina2 prst">
      <div className="ProstinaItemA">
      <span>Название карточки</span>
      </div>
      <div className="ProstinaItemB">
        <span>Название карточки</span></div>
      <div className="ProstinaItemC">
        <span>Название карточки</span></div>
      <div className="ProstinaItemD">
      <span>Название карточки</span>
      </div>

    </div>
    <div className="Prostina2_1 prst">
      <div className="ProstinaItemA">
      <span>Название карточки</span></div>
      <div className="ProstinaItemB">
      <span>Название карточки</span></div>
      <div className="ProstinaItemC">
      <span>Название карточки</span></div>
      <div className="ProstinaItemD">
      <span>Название карточки</span></div>
    </div>
    <div className="Prostina2_2 prst">
      <div className="ProstinaItemA">
      <span>Название карточки</span></div>
      <div className="ProstinaItemB">
      <span>Название карточки</span></div>
      <div className="ProstinaItemC">
      <span>Название карточки</span></div>
      <div className="ProstinaItemD">
      <span>Название карточки</span></div>
    </div>
*/}

    <div className="wraper">
  {/*  <a name="randKus"></a>
    <p className="mText"> Коллекционирую кусочки знаний из&nbsp;разных источников. Сюда попадает все, что мне показалось интересным или занимательным</p>
    <div className="BlockWrap" id="kusWrap">
  <Kus/>
    </div>
*/}
    <div className="BlockWrap" id="mailblock">
    <p id="dlyanachala">Чтобы со мной поработать, нужно написать мне на почту: </p>
    <h1><a href="mailto:dima@legostaeff.me">dima@legostaeff.me</a></h1>
    <img id='decor1' src={Cat2}/>
    <img id='decor2' src={Cat3}/>
    </div>
    </div>
    <div className="BlockWrap" id="basement">
    <p className="mText"> Дима Легостаев </p>
    <p className="mText">синс 1991</p>
    </div>

  </div>
  );
}

export default App;
