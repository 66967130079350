import rak1 from './media/rako.jpg';

function Do (props){
  return(
    <div className={props.classN} id={props.id}>
    <a href={props.link} className="logo1" target={props.target} id="do">
      <img src={props.pict} className="do" id={props.imgId}/>
      <br/>
      <h3>{props.header}</h3>
      <br/>
      <p className="description">{props.description}</p>
      </a>
      </div>
  )
}
export default Do;
