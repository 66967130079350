import rak1 from './media/rako.jpg';

function Do (props){
  return(
    <div className={props.classN}>
    <a href={props.link} className="logo1" id="do">
      <img src={props.pict} id={props.imgId} className="do"/>
      <br/>
      <h3>{props.header}</h3>
      <br/>
      <p className="description">{props.description}</p>
      </a>
      </div>
  )
}
export default Do;
