import React, {useState} from 'react';
import kuso4ki from './kuso4ki.js';

function Kusochki(props){
  // console.log(kuso4ki);
  let x= Math.floor(Math.random()*kuso4ki.length);
  console.log(kuso4ki.length);
  const [index, setIndex] = useState(x);
  // var mydata = JSON.parse(kus);
  // console.log(mydata);
  return(
<div className="BlockWrap" id="kusBckg">
  <div>
  <h3 className="kusHeader"> #{index+1}</h3>
  <button onClick={() => setIndex(Math.floor(Math.random()*kuso4ki.length))}>Еще</button>
  </div>
  <div>
    <p className="mText" id="kusText"><i> {kuso4ki[index].text}</i></p>

  </div>
  <div>
  <p className="mText" id="kusName">Источник: <a id="isto" href={kuso4ki[index].link}>{kuso4ki[index].name}</a></p>

</div>
</div>
  )
}

export default Kusochki;
